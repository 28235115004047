/* You can add global styles to this file, and also import other style files */
/*
@import url("./assets/bootstrap-5.0.0-dist/css/bootstrap.css");
*/

@import url("./assets/bootstrap-4.5.2-dist/css/bootstrap.css");
@import url("../node_modules/@angular/material/prebuilt-themes/pink-bluegrey.css");

.canano-tooltip {
    font-size: 15px;
    border: 1px solid black;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
